import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { BUTTON_STYLES } from '../components/primitives/Button'
import { Layout } from '../components/Layout'

export default () => {
  return (
    <Layout
      title="Home"
      description="Darren Galway a software designer &amp; developer based out of Waterloo, Ontario, Canada."
    >
      <main className="lg:h-full lg:flex lg:items-center lg:justify-center mt-6 pt-12 lg:pt-0 pb-12 lg:pb-0">
        <div
          className="absolute left-0 top-0 bottom-0 h-screen hidden lg:block overflow-hidden"
          aria-hidden
          role="presentation"
        >
          <svg viewBox="0 0 782 1117" fill="none" className="h-full">
            <path
              d="M645 0H0V1117H501.5L419.138 808.25C409.793 773.215 420.144 735.865 446.192 710.64L750.851 415.598C780.481 386.903 789.453 342.959 773.442 304.946L645 0Z"
              className="fill-gray-200 dark:fill-gray-900"
            />
          </svg>
        </div>

        <div className="max-w-5xl rounded-xl px-4 lg:p-12 lg:flex">
          <div className="flex items-center justify-center rounded-lg mb-10 lg:mb-0 lg:mr-16">
            <div className="animate-in h-[12rem] w-[12rem] lg:h-[18rem] lg:w-[18rem] relative lg:flex-none lg:self-start">
              <div className="absolute inset-0 translate-x-4 -translate-y-4 rounded-xl w-full h-full bg-blue-400 shadow-md"></div>
              <StaticImage
                src="../images/me_summer.jpeg"
                alt="Darren with his dog"
                className="object-cover w-full h-full rounded-xl relative z-10 shadow-md"
                placeholder="blurred"
                loading="eager"
              />
              <div className="absolute shadow-xl inset-0 -translate-x-4 translate-y-4 rounded-xl w-full h-full bg-red-400"></div>
            </div>
          </div>
          <div className="animate-in flex flex-col relative z-10">
            <h1 className="text-2xl lg:text-4xl mb-4 font-sans-bold">
              Hey, I'm Darren 👋
            </h1>
            <p className="mb-3 text-xl lg:text-2xl text-gray-700 dark:text-gray-300">
              I'm a software designer & developer based out of Waterloo,
              Ontario, Canada.
            </p>
            <p className="mb-6 text-gray-600 dark:text-gray-400 text-lg lg:text-xl leading-relaxed">
              I work at{' '}
              <a
                href="https://vehikl.com/"
                rel="noopener noreferrer"
                target="_blank"
                className="text-blue-600 hover:underline transition-all hover:text-blue-500 underline-offset-2"
              >
                Vehikl
              </a>{' '}
              as a senior product designer and front-end developer and work on
              best-in-class web applications written with popular frameworks
              such as Vue, and React.
            </p>

            <div className="flex flex-col lg:flex-row">
              <Link
                to="/resume/"
                className={`${BUTTON_STYLES} mb-4 lg:mb-0 lg:mr-4`}
              >
                View my resume
              </Link>
              <Link to="/contact/" className={BUTTON_STYLES}>
                Get in touch
              </Link>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}
